import React from 'react';
import {
  ColorWrapper,
  Container,
  ImageMove,
  PageContainer,
  TextSpaceMargin,
  GridColorWrapper,
  GridLeft,
  GridImage,
  ImageTitle,
  GridTitle,
  CenterList,
  EventBlock,
  Date,
  EventName,
  SourceText,
  MobileColorWrapper,
} from './styles';
import moving from './images/01.png';
import {SectionText, SectionTitle} from '../../atoms';
import Gallery from '../Gallery/Gallery';
import PlayerGrey from '../../atoms/PlayerGrey/PlayerGrey';
import img1 from './images/Jan Szczepkowski w czasacj krakowskich, ok. 1900-1914.jpg';
import img2 from './images/Jan Szczepkowski w mundurze polskim.jpg';
import img3 from './images/Maria i Jan na ławce na świeżo wytyczonej ulicy w Milanówku.jpg';
import img4 from './images/Jan Szczepkowski razem z żoną Marią.jpg';
import img5 from './images/Jan Szczepkowski w milanowskim ogrodzie.jpg';
import img6 from './images/Jan Szczepkowski przy stole kreślarskim (1936r.) NAC.jpg';
import img6_2 from './images/Jan Szczepkowski odtwarza model pomnika Adama Mickiewicza w Warszawie w pracowni Przedsiębiorstwa Sztuk Pięknych.jpg';
import img7 from './images/Nekrologi artysty w Życiu Warszawy  Nr 45 (6326) piątek, 21 lutego, 1964 r..jpg';
import img8 from './images/IMG_7544.jpg';
import img9 from './images/Jan Szczepkowski (z prawej) z architektem Kazimierzem Skórewiczem (w fotelu) na podwórzu gmachu Sejmu.jpg';
import img10 from './images/Jan Szczepkowski w gronie kolegów (1914 r.).jpg';
import img11 from './images/Jan Szczepkowski wraz z żoną Marią i teściową Walerią Morozowicz zaraz po powrocie z podróży poślubnej do Włoch.jpg';
import img12 from './images/Szopka Zielonego Balonika (ok. 1908 r.) Polona kopia.jpg';
import img13 from './images/Aniołek lub pastuszek adoracyjny.jpg';
import img14 from './images/Anioł z trąbką NAC.jpg';
import img15 from './images/Anioł ze skrzypcami NAC.jpg';
import img16 from './images/Odlew postaci Wojciecha Bogusławskiego (1936 r.) NAC.jpg';
import img17 from './images/Maska pośmiertna Marszałka Józefa Piłsudskiego 1935 r. NAC.jpg';
import img18 from './images/Projekt konkursowy autorstwa artysty rzeźbiarza Jana Szczepkowskiego na pomnik marszałka Józefa Piłsudskiego na placu Na Rozdrożu w Warszawie.jpg';
import img19 from './images/Projekt konkursowy autorstwa artysty rzeźbiarza Jana Szczepkowskiego, który otrzymał I nagrodę, 1926r. NAC.jpg';
import img20 from './images/Projekt pomnika Wojciecha Bogusławskiego NAC.jpg';
import img21 from './images/Afisz zapraszający na zabawę taneczna organizowaną przez Zielony Balonik.jpg';
import img22 from './images/Projekt jednego z licznych cmentarzy wojennych wykonany w okresie pracy w Kriegsgräber-Abteilung .jpg';
import img23 from './images/Karykatura pisarza Benedykta Hertza. Z tyłu dopisek _Benedykt Hertz w _urżniętym widzie_..jpg';
import img24 from './images/Poeta Zdzisław Kleszczyński i malarz Konstanty Mackiewicz nasatyrycznym rysunki Jana Szczepkowskiego, 1926 r..jpg';

const AuthorPage = () => {
  return (
    <PageContainer backgroundColor={'#a3c0c0'} style={{paddingBottom: 0}}>
      <Container>
        <ImageMove src={moving} />
        <TextSpaceMargin>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <SectionTitle color={'#525252'} jan={true}>
              JAN SZCZEPKOWSKI
            </SectionTitle>
            <PlayerGrey src={require('../../../audio/jan_szczepowski.mp3')} />
          </div>
          <SectionText color={'#525252'} style={{paddingTop: '1.5rem'}}>
            &emsp;&emsp;Jan Szczepkowski urodził się 8 marca 1878
            w&nbsp;Stanisławowie w&nbsp;Galicji jako syn Alojzego
            Szczepkowskiego (1839-1916) - urzędnika kolejowego i&nbsp;Józefy
            z&nbsp;Kuczyńskich (1840-1891). Dorastał w&nbsp;rodzinie
            pielęgnującej wartości patriotyczne. W&nbsp;swoich wspomnieniach
            przytacza fakt, iż jego matka w&nbsp;wieku 13 lat zaangażowała się w
            pomoc powstańcom styczniowym we Lwowie.
            <br />
            <br />
            &emsp;&emsp;W&nbsp;1891 roku rozpoczął naukę rzeźbiarstwa
            ornamentalnego w&nbsp;Cesarsko-Królewskiej Szkole Zawodowej
            Przemysłu Drzewnego. Według badającej twórczość artysty Katarzyny
            Chruździńśkiej – Uhera w&nbsp;tym okresie należy upatrywać genezy
            stylu, jaki prezentował w&nbsp;latach 20. Choć sam w&nbsp;swoich
            wspomnieniach nie odnosi się ze zbytnim sentymentem do tego okresu,
            zdawał sobie sprawę, że to właśnie tam miał pierwszy kontakt
            z&nbsp;Podhalem i&nbsp;sztuką ludową. W&nbsp;szkole posiadł
            praktyczną znajomość rzemiosła snycerskiego, która
            w&nbsp;późniejszych latach wielokrotnie okazała się użyteczna.
            <Gallery
              maxWidth={'60'}
              columnsNumber={2}
              isImgFit
              hoverColor="rgba(163, 192, 192, 0.4)"
              colorSign="#525252"
              images={[
                {
                  src: img1,
                  sign: 'Jan Szczepkowski w czasach krakowskich, ok. 1900-1914',
                },
                {
                  src: img2,
                  sign: 'Jan Szczepkowski w mundurze polskim',
                },
              ]}
            />
            &emsp;&emsp;Ostateczne wykształcenie zdobył w&nbsp;Akademii Sztuk
            Pięknych w&nbsp;Krakowie, studiując rzeźbę pod kierunkiem Alfreda
            Dauna i&nbsp;Konstantego Laszczki. Okres studiów zbiegł się ze
            zmianą kierownictwa akademii. Po trwającej dwadzieścia lat dyrekcji
            Jana Matejki jego następcą został Julian Fałat. Była to zmiana dla
            Szczepkowskiego znacząca, bo dotychczasowa docentura rzeźby została
            usamodzielniona i&nbsp;z&nbsp;przedmiotu pomocniczego stała się
            przedmiotem głównym. Kierownictwo nad szkołą rzeźby objął Alfred
            Daun. Szczepkowski w&nbsp;tym okresie pracował dorywczo, wykonując
            ornamenty w pracowniach sztukatorskich. W&nbsp;krakowskim okresie
            tworzył głównie portrety. Charakterystyczne dla tego czasu są
            realizm połączony z&nbsp;młodopolskim symbolizmem i&nbsp;wpływami
            Rodina.
            <br />
            <br /> &emsp;&emsp;W&nbsp;1900 roku Szczepkowski otrzymał propozycję
            stanowiska kierownika artystycznego w&nbsp;biurze inż. arch.
            Floriana Wyganowskiego. Wiązała się ona z&nbsp;pracą przy Wystawie
            Jubileuszowej z&nbsp;okazji 700-lecia Rygi. Pobyt przerwało wezwanie
            do stawienia się w&nbsp;Krakowie w&nbsp;celu odbycia służby
            wojskowej w&nbsp;armii austriackiej. Była to tzw. służba
            jednorocznych ochotników, zakończona egzaminem oficerskim i
            przeniesieniem do rezerwy. W&nbsp;późniejszych latach Szczepkowski
            podjął pracę w&nbsp;Żeńskiej Szkole Sztuk Pięknych i&nbsp;Sztuki
            Stosowanej (Przemysłu Artystycznego). W&nbsp;1902 został
            kierownikiem artystycznym działu majoliki w&nbsp;fabryce fajansów
            Józef Niedźwiecki i&nbsp;Spółka w&nbsp;Dębnikach pod Krakowem.
            <br />
            <br /> &emsp;&emsp;W&nbsp;roku 1907 otrzymał roczne stypendium
            Czartoryskich i&nbsp;wyjechał do Paryża. Stypendium przedłużyło się
            do dwóch lat. Przy przedłużeniu – które było poprzedzone podaniem
            Szczepkowskiego – nie bez znaczenia było poparcie Auguste’a Rodina.
            Rzeźbiarz wywarł na niego duży wpływ, a&nbsp;Szczepkowski lubił
            nazywać się uczniem Rodina. Choć praktyki jako takiej u&nbsp;niego
            nie odbywał. Obcował jednakże z jego dziełami i&nbsp;ich twórcą.
            Z&nbsp;podobną atencją wypowiadał się o&nbsp;Emilu A. Bourdell’u,
            tyle że ich relacja miała znamiona bardziej przyjacielskie niż
            mistrz-uczeń.
          </SectionText>
        </TextSpaceMargin>
      </Container>
      <ColorWrapper
        bgColor={'#525252'}
        style={{marginTop: '3rem', paddingBottom: '2rem'}}>
        <Container>
          <Gallery
            maxWidth={'60'}
            columnsNumber={2}
            isImgFit
            hoverColor="rgba(163, 192, 192, 0.4)"
            colorSign="#fbfbfb"
            images={[
              {
                src: img3,
                sign:
                  'Maria i Jan na ławce na świeżo wytyczonej ulicy w Milanówku',
              },
              {
                src: img4,
                sign: 'Jan Szczepkowski razem z żoną Marią',
              },
            ]}
          />
          <SectionText color={'#fbfbfb'}>
            &emsp;&emsp;Szczepkowski wrócił następnie do Krakowa i&nbsp;swojego
            życia towarzyskiego. Poznał wtedy obiecującą aktorkę Marię
            Morozowicz, córkę znanego aktora Rufina Morozowicza. Znajomość
            przerodziła się w coś poważniejszego. Para wzięła ślub 18 maja 1913
            r. w&nbsp;kościele bernardynów w&nbsp;Krakowie. Świadkami zostali
            rzeźbiarz Xawery Dunikowski i&nbsp;prawnik Albin Chęciński. Młoda
            para w&nbsp;podróż poślubną udała się do Włoch.
            <br />
            <br />
            &emsp;&emsp;W 1914 r. jako delegat Akademii Krakowskiej
            w&nbsp;charakterze jurora, odbył podróż do Bułgarii obradować
            w&nbsp;konkursie na pomnik bułgarskiego bohatera narodowego Hadżi
            Dimitra. W&nbsp;tym samym roku został zmobilizowany do armii
            austro-węgierskiej. W&nbsp;Krakowie pozostawił będącą w&nbsp;ciąży
            młodą żonę. Niedługo później poważnie ranny (prawdopodobnie 3 V 1915
            r. w&nbsp;bitwie pod Kraśnikiem) i&nbsp;niezdolny do dalszej służby
            został przydzielony do Kriegsgräber-Abteilung – oddziału zajmującego
            się budowaniem cmentarzy wojskowych. <br />
            <br />W roku 1916 zmarł jego ojciec. Pod koniec wojny odwiózł żonę
            i&nbsp;córkę Hannę do domu teściów w&nbsp;Milanówku. Pod koniec 1918
            r. Szczepkowski został zwolniony ze służby. W&nbsp;lipcu roku 1920
            wstąpił jako ochotnik do Wojska Polskiego. Nie służył zbyt długo, bo
            demobilizacja oficerów ochotników nastąpiła już w&nbsp;grudniu tego
            samego roku. <br />
            <br />
            &emsp;&emsp;Lata 20. i&nbsp;30. dwudziestego wieku były okresem
            największych sukcesów artystycznych i&nbsp;zawodowych. Przełomowy
            dla jego twórczości był rok 1925. Na Międzynarodowej Wystawie Sztuk
            Dekoracyjnych i&nbsp;Nowoczesnego Przemysłu w&nbsp;Paryżu odniósł
            jeden ze swoich największych sukcesów. Duża w&nbsp;tym rola
            mianowanego na Generalnego Delegata Polski na Wystawę Jerzego
            Warchałowskiego. Warchałowski wybrał artystów najbliższych mu ideowo
            i&nbsp;estetycznie z&nbsp;kręgów poszukujących polskiego stylu
            narodowego. Taka jednolitość polskiego działu przy zachowaniu
            wysokiego poziomu wszystkich prac przełożyła się na wielki sukces
            polskich wystawców w&nbsp;postaci 172 nagród. Najbardziej
            spektakularnym sukcesem był zakup przez rząd francuski Kapliczki
            Bożego Narodzenia Jana Szczepkowskiego. Szczepkowski otrzymał za
            ołtarz Grand Prix i&nbsp;Legię Honorową. Otworzyło to także
            najaktywniejszy okres w&nbsp;życiu zawodowym artysty. W&nbsp;tym
            czasie powstały m.in. dekoracyjny płaskorzeźbiony fryz na rotundzie
            sali obrad Sejmu RP (1927–1928) czy fryz dla Teatru Ateneum (1929)
            zniszczony w&nbsp;1939 r.
            <Gallery
              style={{marginBottom: '2rem', gridGap: '2rem 1rem'}}
              columnsNumber={3}
              isImgFit
              hoverColor="rgba(163, 192, 192, 0.4)"
              colorSign="#fbfbfb"
              images={[
                {
                  src: img5,
                  sign: 'Jan Szczepkowski w milanowskim ogrodzie',
                },
                {
                  src: img6,
                  sign: 'Jan Szczepkowski przy stole kreślarskim (1936r.) NAC',
                },
                {
                  src: img6_2,
                  sign:
                    'Jan Szczepkowski odtwarza model pomnika Adama Mickiewicza w Warszawie w pracowni Przedsiębiorstwa Sztuk Pięknych',
                },
              ]}
            />
            &emsp;&emsp;II Wojnę Światową Szczepkowski przeżył w&nbsp;Milanówku,
            do którego przeprowadził się z&nbsp;rodziną w&nbsp;1936 r. po
            dymisji ze stanowiska dyrektora Miejskiej Szkoły Sztuk Zdobniczych
            w&nbsp;Warszawie. Córka i&nbsp;zięć Szczepkowskiego pracowali
            w&nbsp;tym czasie dla wywiadu Armii Krajowej oraz walczyli
            w&nbsp;Powstaniu Warszawskim. Po upadku powstania, w&nbsp;willi
            „Waleria” schronienie znalazło wielu uciekinierów ze stolicy. Przez
            pewien czas „Waleria” była zajęta przez okupantów.
            W&nbsp;ostatecznym rozrachunku rodzinie udało się jednak przetrwać
            wojnę w&nbsp;komplecie. Pomimo sytuacji w&nbsp;kraju Szczepkowski
            nadal intensywnie pracował. Na początku lat 40. przygotowywał plany
            odbudowy Warszawy. Rzeźbił portrety swoich bliskich. Uczestniczył
            w&nbsp;działaniach mających doprowadzić do odbudowania instytucji
            życia kulturalnego w Polsce. 1 lutego 1945 r. w&nbsp;Milanówku
            powołano Oddział Warszawski Związku Zawodowego Polskich Artystów
            Plastyków. Jan Szczepkowski został członkiem Komisji
            Kwalifikacyjnej. Jednym z&nbsp;efektów tej działalności była
            zorganizowana 11 sierpnia 1945 r. wystawa artystów okręgu
            warszawskiego, w&nbsp;której Szczepkowski wziął udział. Rzeźbiarz
            był aktywnym członkiem związku. W&nbsp;1947 r. został pierwszym
            przewodniczącym Rady Instytutu nadzorującym działania Centralnego
            Instytutu Kultury odpowiedzialnego za koordynację działań
            z&nbsp;zakresu kultury. Za zadanie postawił sobie upowszechnianie
            sztuki i&nbsp;kultury tak by „zajrzała do najciemniejszego kąta, by
            stała się udziałem mas, by wlać treść ideową w&nbsp;hasło piękne,
            lecz jeszcze nie wypełnione treścią, prawdziwej Rzeczypospolitej
            demokratycznej, rzeczywistej Polski ludowej” – jak ujął to
            w&nbsp;mowie otwierającej zebranie inaugurujące jego funkcję.
            W&nbsp;roku 1957 jako członek Zarządu Głównego Związku Polskich
            Artystów Plastyków uczestniczył w&nbsp; plenarnym zebraniu Rady
            Ochrony Pamięci Walk i&nbsp;Męczeństwa. W&nbsp;lipcu 1958 roku
            znalazł się w&nbsp;gronie 23 artystów plastyków i&nbsp;działaczy
            społecznych, którzy wyłonili Tymczasowy Komitet Organizacyjny
            Towarzystwa Przyjaciół Sztuk Pięknych w&nbsp;Warszawie.
            <Gallery
              maxWidth={'60'}
              columnsNumber={2}
              isImgFit
              hoverColor="rgba(163, 192, 192, 0.4)"
              colorSign="#fbfbfb"
              images={[
                {
                  src: img7,
                  sign:
                    'Nekrologi artysty w Życiu Warszawy  Nr 45 (6326) piątek, 21 lutego, 1964 r.',
                },
                {
                  src: img8,
                  sign:
                    'Grób Jana Szczepkowskiego na cmentarzu powązkowskim w Warszawie',
                },
              ]}
            />
            &emsp;&emsp;Jan Szczepkowski zmarł 17 lutego 1964 roku w&nbsp;wieku
            85 lat. Pochowany został 21 lutego na Cmentarzu Powązkowskim
            w&nbsp;Warszawie w&nbsp;Alei Zasłużonych (rząd 1, miejsce 28).
            Autorami jego nagrobka są Zofia i&nbsp;Karol Tchorek.
          </SectionText>
        </Container>
        <Container style={{marginTop: '2rem'}}>
          <p
            style={{
              fontFamily: 'LATOREGULAR',
              fontSize: '10px',
              lineHeight: 1.2,
              textAlign: 'justify',
              color: '#ffffff',
            }}>
            Źródła informacji:
            <br /> <br />
            Bartoszek Monika, Jan Szczepkowski, Centrum Rzeźby Polskiej w
            Orońsku, Orońsko, 2014
            <br /> <br />
            Chrudzimska – Uhera Katarzyna, JAN SZCZEPKOWSKI, ŻYCIE I TWÓRCZOŚĆ,
            Urząd Miejski w Milanówku, 2008 r.
            <br /> <br />
            Chrudzimska-Uhera Katarzyna, Małżeństwo z rozsądku? : czyli o
            artystycznych dylematach i kompromisach w czasach władzy ludowej, w
            oparciu o działalność społeczną, wypowiedzi i projekty rzeźbiarskie
            Jana Szczepkowskiego z lat 1945-1964, Saeculum Christianum : pismo
            historyczne
            <br /> <br />
            Chrudzimska-Uhera Katarzyna, O rzeźbiarzu (nieco) zapomnianym,
            Niepodległość i Pamięć 17/1 (31), 257-271, 2010
            <br /> <br />
            Chrudzimska-Uhera Katarzyna, Prof. Jan Szczepkowski i jego dzieło
            (1878-1964), Milanówek Miejsce Magiczne pod red. Andrzeja Pettyna,
            Towarzystwo Miłośników Milanówka, 2005
            <br /> <br />
            Szczepkowski Jan, Wypukłe i wklęsłe. Wspomnienia, Wstęp, opracowanie
            i komentarz Katarzyna Chrudzimska - Uhera, Urząd Miasta Milanówka,
            Milanówek, 2010
          </p>
        </Container>
      </ColorWrapper>
      <GridColorWrapper bgColor={'#a3c0c0'}>
        <GridLeft></GridLeft>
        <GridTitle>
          <SectionTitle color={'#525252'} Kalendarium>
            Kalendarium
          </SectionTitle>
          <CenterList>
            <EventBlock>
              <Date>1878, 8 marca</Date>
              <EventName>narodziny w&nbsp;Stanisławowie</EventName>
            </EventBlock>
            <EventBlock>
              <Date>18.09.1891- 13.07.1895</Date>
              <EventName>
                edukacja w C. K. Szkole Zawodowej Przemysłu Drzewnego
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1891</Date>
              <EventName>śmierć matki</EventName>
            </EventBlock>
            <EventBlock>
              <Date>1895-1900</Date>
              <EventName>
                studia na Akademii Sztuk Pięknych w&nbsp;Krakowie
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1901</Date>
              <EventName>
                kierownictwo artystyczne w biurze inż. arch. Floriana
                Wyganowskiego w&nbsp;Rydze
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1901/1902</Date>
              <EventName>
                roczna obowiązkowa służba wojskowa w&nbsp;c.k. armii
                austriackiej w 13 pułku piechoty
              </EventName>
            </EventBlock>

            <EventBlock>
              <Date>1901</Date>
              <EventName>
                podjęcie pracy w Żeńskiej Szkole Sztuk Pięknych i&nbsp;Sztuki
                Stosowanej dla kobiet w&nbsp;Krakowie
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1902-1905</Date>
              <EventName>
                kierownictwo artystyczne działy majoliki w&nbsp;fabryce fajansów
                Józef Niedźwiecki i&nbsp;Spółka w&nbsp;Dębnikach pod Krakowem
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1907-1908</Date>
              <EventName>
                pobyt w&nbsp;Paryżu na stypendium Fundacji Książąt Czartoryskich
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1913, 18 maja</Date>
              <EventName>
                ślub Jana Szczepkowskiego z&nbsp;Marią Morozowicz
              </EventName>
            </EventBlock>

            <EventBlock>
              <Date>1914, lipiec</Date>
              <EventName>
                podróż do Bułgarii w&nbsp;charakterze jurora konkursu na pomnik
                Hadżi Dimitra
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1914-1918</Date>
              <EventName>
                I Wojna Światowa, służba w&nbsp;c.k. armii, przydział do
                Kriegsgräberabteilung – oddziału zajmującego się budowaniem
                cmentarzy wojskowych, służba w&nbsp;Komendzie Stacyjnej Obozu
                Jeńców w&nbsp;Wadowicach
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1916</Date>
              <EventName>śmierć ojca</EventName>
            </EventBlock>
            <EventBlock>
              <Date>1920</Date>
              <EventName>służba ochotnicza w&nbsp;Wojsku Polskim</EventName>
            </EventBlock>
            <EventBlock>
              <Date>ok. 1921-1936</Date>
              <EventName>
                praca pedagoga, a&nbsp;od 1923 r. dyrektora w&nbsp;Miejskiej
                Szkole Sztuk Zdobniczych i&nbsp;Malarstwa w&nbsp;Warszawie
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1925</Date>
              <EventName>
                zdobycie Grand Prix Międzynarodowej Wystawy Sztuki Dekoracyjnej
                i&nbsp;Przemysłu w&nbsp;Paryżu
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1939-1945</Date>
              <EventName>Szczepkowski przebywa w Milanówku</EventName>
            </EventBlock>
            <EventBlock>
              <Date>od 1945</Date>
              <EventName>
                Szczepkowski organizuje i&nbsp;działa w&nbsp;strukturach Związku
                Artystów Polskich Plastyków, przewodniczy Radzie Centralnego
                Instytutu Kultury
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1964, 17 lutego</Date>
              <EventName>śmierć Jana Szczepkowskiego</EventName>
            </EventBlock>
          </CenterList>
        </GridTitle>
        <GridImage>
          <ImageTitle>Autoportret, 1960</ImageTitle>
          <SourceText>
            Kalendarium pochodzi ze wspomnień Jana Szczepkowskiego „Wypukłe
            i&nbsp;wklęsłe” w&nbsp;opracowaniu i&nbsp;z&nbsp;komentarzem
            Katarzyny Chrudzimskiej - Uhera. Szczepkowski Jan, Wypukłe
            i&nbsp;wklęsłe. Wspomnienia, Wstęp, opracowanie i&nbsp;komentarz
            Katarzyna Chrudzimska - Uhera, Urząd Miasta Milanówka, Milanówek,
            2010
          </SourceText>
        </GridImage>
        <div />
      </GridColorWrapper>
      <MobileColorWrapper>
        <Container>
          <p
            style={{
              fontFamily: 'LATOREGULAR',
              fontSize: '10px',
              lineHeight: 1.2,
              textAlign: 'justify',
              color: '#525252',
            }}>
            Kalendarium pochodzi ze wspomnień Jana Szczepkowskiego „Wypukłe
            i&nbsp;wklęsłe” w&nbsp;opracowaniu i&nbsp;z&nbsp;komentarzem
            Katarzyny Chrudzimskiej - Uhera. Szczepkowski Jan, Wypukłe
            i&nbsp;wklęsłe. Wspomnienia, Wstęp, opracowanie i&nbsp;komentarz
            Katarzyna Chrudzimska - Uhera, Urząd Miasta Milanówka, Milanówek,
            2010
          </p>
        </Container>
      </MobileColorWrapper>
      <ColorWrapper bgColor={'#525252'} style={{paddingBottom: '2rem'}}>
        >
        <Container style={{zIndex: 99}}>
          <Gallery
            style={{gridGap: '3rem 1rem'}}
            columnsNumber={4}
            isImgFit
            hoverColor="rgba(163, 192, 192, 0.4)"
            colorSign="#ffffff"
            images={[
              {
                src: img9,
                sign:
                  'Jan Szczepkowski (z prawej) z architektem Kazimierzem Skórewiczem (w fotelu) na podwórzu gmachu Sejmu',
              },
              {
                src: img10,
                sign: 'Jan Szczepkowski w gronie kolegów (1914 r.)',
              },
              {
                src: img11,
                sign:
                  'Jan Szczepkowski wraz z żoną Marią i teściową Walerią Morozowicz zaraz po powrocie z podróży poślubnej do Włoch',
              },
              {
                src: img12,
                sign: 'Szopka Zielonego Balonika (ok. 1908 r.) Polona',
              },
              {
                src: img14,
                sign: 'Anioł z trąbką NAC',
              },
              {
                src: img15,
                sign: 'Anioł ze skrzypcami NAC',
              },
              {
                src: img20,
                sign: 'Projekt pomnika Wojciecha Bogusławskiego NAC',
              },

              {
                src: img16,
                sign: 'Odlew postaci Wojciecha Bogusławskiego (1936 r.) NAC',
              },
              {
                src: img17,
                sign:
                  'Maska pośmiertna Marszałka Józefa Piłsudskiego 1935 r. NAC',
              },
              {
                src: img18,
                sign:
                  'Projekt konkursowy autorstwa artysty rzeźbiarza Jana Szczepkowskiego na pomnik marszałka Józefa Piłsudskiego na placu Na Rozdrożu w Warszawie',
              },
              {
                src: img19,
                sign:
                  'Projekt konkursowy autorstwa artysty rzeźbiarza Jana Szczepkowskiego, który otrzymał I nagrodę, 1926r. NAC',
              },
              {
                src: img13,
                sign: 'Aniołek lub pastuszek adoracyjny',
              },
              {
                src: img21,
                sign:
                  'Afisz zapraszający na zabawę taneczna organizowaną przez Zielony Balonik',
              },
              {
                src: img22,
                sign:
                  'Projekt jednego z licznych cmentarzy wojennych wykonany w okresie pracy w Kriegsgräber-Abteilung',
              },
              {
                src: img23,
                sign:
                  'Karykatura pisarza Benedykta Hertza. Z tyłu dopisek Benedykt Hertz w urżniętym widzie',
              },
              {
                src: img24,
                sign:
                  'Poeta Zdzisław Kleszczyński i malarz Konstanty Mackiewicz nasatyrycznym rysunki Jana Szczepkowskiego, 1926 r.',
              },
            ]}
          />
        </Container>
      </ColorWrapper>
      <ColorWrapper bgColor={'#a3c0c0'}>
        <Container>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/uOwF-d-CCUo?controls=0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Container>
      </ColorWrapper>
    </PageContainer>
  );
};

export default AuthorPage;
