import React from 'react';
import SEO from '../../components/seo';
import AuthorPage from '../../components/desktop/AuthorPage/AuthorPage';

const Artist = () => {
  return (
    <>
      <SEO title={'Życiorys'} />
      <AuthorPage />
    </>
  );
};

export default Artist;
